/* You can add global styles to this file, and also import other style files */

/*
@File: Zico Template Style

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Home One CSS
** - Home Two CSS
** - Home Three CSS
** - About CSS
** - About Me CSS
** - Work Details CSS
** - Video Details CSS
** - Blog Details CSS
** - Partner CSS
** - Pricing CSS
** - FAQ CSS
** - Error CSS
** - Coming Soon CSS
** - Privacy Policy CSS
** - Contact CSS
** - Preloader CSS
** - Back to Top CSS
*/

$common-font: 'Playfair Display', serif;
$heading-font: 'Josefin Sans', sans-serif;
$transition: .5s all ease;
$all-size: 16px;
$white-color: #ffffff;
$grey-color: #d5d7d7;
$gold-color: #c1ab65;
/*-- Home One --*/
$gradient-green: linear-gradient(90deg, rgba(16,195,23,1) 23%, rgba(21,208,154,1) 64%);


/*-- Default CSS --*/
body {
    font-family: $common-font;
    color: $grey-color;
    background-color: #0c0c0c;
    font-size: $all-size;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    font-family: $heading-font;
}
p {
    line-height: 1.8;
}
a {
    transition: $transition;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
img {
    max-width: 100%;
}
.d-table {
    width:100%;
    height:100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pb-100 {
    padding-bottom: 100px;
}
button {
    &:focus{
        outline: 0;
    }
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
.container {
    max-width: 1270px;
}
/*-- End Default CSS --*/

/*-- Navbar --*/
.main-nav {
    background: #000000b8;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: $transition;

    .container {
        max-width: 1620px;
    }
    &.menu-shrink {
        background-color: #000000;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    nav {
        .navbar-nav {
            margin-left: auto;
            margin-right: auto;
            
            &.active>.nav-link, .nav-link.active, .nav-link.show, &.show>.nav-link {
                color: $gold-color;
            }
            .nav-item {
                i {
                    display: inline-block;
                    font-size: 20px;
                    top: 4px;
                    right: 2px;
                    position: relative;
                }
                &:hover {
                    a {
                        color: $gold-color;
                    }
                }
                a {
                    font-weight: 600;
                    font-size: 17px;
                    transition: $transition;
                    font-family: $heading-font;
                    text-transform: capitalize;

                    &:hover, &:focus, &.active {
                        color: $gold-color;
                    }
                }
                .dropdown-menu {
                    background: $white-color;
                    padding-top: 5px;
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 5px;

                    li {
                        border-bottom: 1px dashed #b8b5b5;
                    
                        &:last-child {
                            border-bottom: 0;
                        }
                        a {
                            font-size: 16px;
                            color: #01091e;
                            position: relative;

                            &:before {
                                position: absolute;
                                content: '';
                                width: 0;
                                height: 2px;
                                top: 19px;
                                left: 0;
                                background-color: $gold-color;
                                opacity: 0;
                                transition: $transition;
                            }
                            &:hover, &:focus, &.active {
                                color: $gold-color;
                                padding-left: 30px;

                                &:before {
                                    opacity: 1;
                                    width: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-toggle::after {
        display: none;
    }
}
.side-nav {
    .modal-btn {
        padding: 0;
        transform: rotate(180deg);
        position: relative;
        top: -6px;
        background-color: transparent;
        border: 0;

        &:hover {
            span {
                &:nth-child(2) {
                    width: 25px;
                }
                &:nth-child(3) {
                    width: 15px;
                }
            }
        }
        span {
            display: block;
            width: 35px;
            height: 3px;
            background-color: $white-color;
            margin-bottom: 5px;
            transition: $transition;
        }
    }
}
.modal-body {
    padding: 40px 30px 50px;

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #000;
    }
    p {
        color: #545555;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .image-area {
        margin-bottom: 10px;

        .col-lg-4 {
            padding-right: 5px;
            margin-right: -5px;
        }
        a {
            display: block;
            margin-bottom: 15px;

            img {
                width: 100%;
            }
        }
    }
    .modal-item {
        margin-bottom: 30px;
    }
    .social-area {
        text-align: center;

        h3 {
            font-size: 20px;
            margin-bottom: 12px;
            font-weight: 600;
            color: #000;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                a {
                    display: block;
                    color: $gold-color;
                    border: 1px solid $gold-color;
                    width: 32px;
                    height: 32px;
                    line-height: 34px;
                    border-radius: 50%;
                    margin-right: 3px;
                    margin-left: 3px;
                    font-size: 16px;
                    text-align: center;

                    &:hover {
                        color: $white-color;
                        background-color: $gold-color;
                    }
                }
            }
        }
    }
}
#myModalRight {
    z-index: 99999;

    .modal-header .close {
        position: relative;
        top: 5px;
        color: #000;
        font-weight: bold;
        padding: 0;
        background-color: transparent;
        border: 0;
        font-size: 35px;
    }
}
.modal {
    &.modal-right {
        .modal-dialog {
            max-width: 380px;
            min-height: 100vh;
        }
        &.show .modal-dialog {
            transform: translate(0, 0);
        }
        .modal-content {
            height: 100vh;
            overflow-y: auto;
            border-radius: 0;
        }
    }
    &.modal-left {
        .modal-dialog {
        transform: translate(-100%, 0);
        margin: 0 auto 0 0;
        }
    }
    &.modal-right {
        .modal-dialog {
        transform: translate(100%, 0);
        margin: 0 0 0 auto;
        }
    }
}
.search-icon {
    font-size: 22px;
    position: relative;
    top: 1px;
    color: $white-color;
    border: 0;
    outline: none;
    transition: $transition;
    background-color: transparent;

    &:hover{
        color: $gold-color;
    }
}
.search-toggle .search-icon.icon-close {
    display: none;
}
.search-toggle.opened .search-icon.icon-search {
    display: none;
}
.search-toggle.opened .search-icon.icon-close {
    display: block;
}
.nav-srh {
    position: relative;
    display: inline-block;
    margin-right: 15px;
    top: 3px;

    .search-area {
        position: absolute;
        right: 8px;
        bottom: -105px;
        z-index: 5;
        transition: $transition;
        max-height: 0;
        overflow: hidden;
        background-color: $white-color;

        .search-icon {
            vertical-align: middle;
            position: absolute;
            right: 30px;
            top: 28px;
            background-color: transparent;
            font-size: 20px;
            color: #515151;
            transition: $transition;

            &:hover {
                color: $gold-color;
            }
        }
        .src-input {
            outline: none;
            padding-left: 20px;
            margin: 0;
            width: 300px;
            background-color: #dddddd80;
            border: 1px solid #ddd;
            font-size: $all-size;
            height: 45px;
            border-radius: 0;
        }
    }
    .search-area.opened {
        max-height: 100px;
        padding: 20px 25px;
        box-shadow: 0px 0px 15px #dddddd80;
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.banner-img-one {
    background-image: url('assets/img/home-one/banner-main1.jpg');
}
.banner-img-two {
    background-image: url('assets/img/home-one/banner-main2.jpg');
}
.banner-img-three {
    background-image: url('assets/img/home-one/banner-main3.jpg');
}
.banner-wrap {
    position: relative;
}
.banner-social {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: rotate(-90deg);

    span {
        display: inline-block;
        font-weight: 600;
        color: $white-color;
    }
    ul {
        margin: 0;
        padding: 0;
        display: inline-block;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                color: $white-color;
                font-size: 16px;

                &:hover {
                    color: $gold-color;
                }
            }
        }
    }
}
.banner-area {
    height: 1000px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .30;
    }
}
.banner-content {
    position: relative;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;

    h1 {
        font-weight: 700;
        font-size: 100px;
        color: $white-color;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    p {
        font-weight: 700;
        font-size: 24px;
        color: $white-color;
        margin-bottom: 40px;
    }
    .banner-btn {
        font-weight: 600;
        color: #01091e;
        background-color: $white-color;
        border-radius: 5px;
        display: inline-block;
        padding: 12px 25px 9px;
        position: relative;
        z-index: 1;
        font-family: $heading-font;

        &:before {
            position: absolute;
            content: '';
            width: 0%;
            height: 0;
            top: 0;
            left: 0;
            background-color: #01091e;
            opacity: 0;
            z-index: -1;
            transition: $transition;
            border-radius: 5px;
        }
        &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            bottom: 0;
            right: 0;
            background-color: #01091e;
            opacity: 0;
            z-index: -1;
            transition: $transition;
            border-radius: 5px;
        }
        &:hover {
            color: $white-color;

            &:before {
                height: 100%;
                width: 100%;
                opacity: 1;
            }
            &:after {
                height: 100%;
                width: 100%;
                opacity: 1;
            }
        }
    }
}
.banner-slider.owl-theme {
    .owl-nav.disabled+.owl-dots {
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 35px;
    }
    .owl-dots .owl-dot span {
        width: 14px;
        height: 14px;
        background: #b8b7b7;
    }
    .owl-dots .owl-dot.active span {
        background: #869791;
        width: 24px;
        height: 24px;
        border: 1px solid $white-color;
        background: transparent;
        position: relative;
        margin: 1px;
    
        &:before {
            position: absolute;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            left: 0;
            right: 0;
            bottom: 5px;
            margin-left: auto;
            margin-right: auto;
            background-color: $white-color;
        }
    }
}
.lines {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    width: 90vw;

    .line {
        position: absolute;
        top: 0;
        height: 100%;
        width: 1px;
        left: 50%;
        background: hsla(0,0%,100%,.1);
        overflow: hidden;
        z-index: 1;

        &:nth-child(1) {
            margin-left: -12.5%;
        }
        &:nth-child(2) {
            margin-left: -25%;
        }
        &:nth-child(3) {
            margin-left: -37.5%;
        }
        &:nth-child(4) {
            margin-left: -49.0%;
        }
        &:nth-child(5) {
            margin-left: 0%;
        }
        &:nth-child(6) {
            margin-left: 12.5%;
        }
        &:nth-child(7) {
            margin-left: 25%;
        }
        &:nth-child(8) {
            margin-left: 37.5%;
        }
        &:nth-child(9) {
            margin-left: 49.5%;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            height: 10vh;
            width: 100%;
            top: -50%;
            left: 0;
            background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff 75%,#fff);
            animation: run 7s 0s infinite;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(.4,.26,0,.97);
        }
    }
}
@keyframes run{
    0% {top:-50%}
    to{top:110%}
}
/*-- End Banner --*/

/*-- Trust --*/
.trust-area {
    background-color: #080808;

    .section-title {
        margin-bottom: 25px;
    }
    .trust-content {
        text-align: center;
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;

        p {
            margin-bottom: 40px;
        }
        .trust-img {
            margin-bottom: 30px;
        }
    }
}
.section-title {
    text-align: center;
    margin-bottom: 40px;
    margin-top: -8px;

    .sub-title {
        font-weight: 700;
        font-size: 17px;
        color: $gold-color;
        display: inline-block;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    h2 {
        font-weight: 600;
        font-size: 45px;
        color: $white-color;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}
/*-- End Trust --*/

/*-- Week --*/
.week-video-btn {
    a {
        top: 35% !important;
    }
}
.week-video-btn-two {
    a {
        top: 32% !important;
    }
}
.week-area {
    .week-item {
        position: relative;
        margin-bottom: 30px;

        &:hover {
            a {
                opacity: 1;
            }
        }
        img {
            width: 100%;
        }
        a {
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            width: 70px;
            height: 70px;
            line-height: 70px;
            top: 43%;
            left: 0;
            right: 0;
            font-size: 40px;
            margin-left: auto;
            margin-right: auto;
            color: $white-color;
            background-color: $gold-color;
            opacity: 0;

            i {
                width: 70px;
                height: 70px;
                line-height: 70px;
            }
            &:hover {
                background-color: #01091e;
            }
        }
    }
    .owl-theme .owl-nav {
        margin-top: 0;
    }
    .owl-prev {
        position: absolute;
        top: 43%;
        left: 15px;
        width: 60px;
        height: 60px;
        font-size: 45px !important;
        line-height: 65px !important;
        border-radius: 50% !important;
        color: #b9b9b5 !important;
        background-color: #111111 !important;
        transition: $transition;

        &:hover {
            color: #111111 !important;
            background-color: $white-color !important;
        }
    }
    .owl-next {
        position: absolute;
        top: 43%;
        right: 15px;
        width: 60px;
        height: 60px;
        font-size: 45px !important;
        line-height: 65px !important;
        border-radius: 50% !important;
        color: #b9b9b5 !important;
        background-color: #111111 !important;
        transition: $transition;

        &:hover {
            color: #111111 !important;
            background-color: $white-color !important;
        }
    }
}
/*-- End Week --*/

/*-- Work --*/
.work-area {
    a {
        display: block;
    }
}
.work-item {
    position: relative;
    text-align: center;
    overflow: hidden;
    
    &:hover {
        img {
            transform: scale(1.1);
        }
        .work-content {
            h3 {
                color: $gold-color;
            }
        }
    }
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.8057328984978493) 32%, rgba(0,0,0,0) 75%);
        transition: $transition;
        z-index: 1;
    }
    img {
        width: 100%;
        transition: $transition;
    }
    .work-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        z-index: 1;

        h3 {
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 5px;
            text-transform: uppercase;
            color: #d4d0d0;
            transition: $transition;
        }
        span {
            display: block;
            color: #d4d0d0;
            font-weight: 600;
            font-size: 24px;
            font-family: $heading-font;
        }
    }
}
/*-- End Work --*/

/*-- History --*/
.history-area {
    background-image: url('assets/img/home-one/history-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    margin: 0;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000000;
        opacity: .90;
        display: block;
    }
    .cd-horizontal-timeline {
        margin: 0;
    }
    .container-fluid {
        max-width: 1450px;
    }
    .section-title {
        position: relative;
    }
    .history-img {
        position: relative;
        margin-bottom: 30px;

        img {
            width: 100%;
        }
    }
    .history-content {
        position: relative;

        h2 {
            color: $white-color;
            font-size: 48px;
            margin-bottom: 10px;
            font-weight: 400;
        }
        p {
            color: #d5d7d7;
            font-size: 17px;
            max-width: 715px;
            margin-bottom: 15px;
        }
        .history-inner {
            margin-bottom: 30px;

            i {
                display: inline-block;
                font-size: 40px;
                margin-bottom: 10px;
                color: $white-color;
            }
            h3 {
                font-size: 20px;
                color: $white-color;
                margin-bottom: 10px;
                text-transform: uppercase;
                font-weight: 600;
            }
            span {
                display: block;
                color: #d5d7d7;
            }
        }
    }
    .timeline {
        width: unset;
        max-width: 100%;
        margin-bottom: 25px;

        .filling-line {
            display: none;
        }
        .events-wrapper {
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
            .events {
                background: #a5a5a5;
                width: 100% !important;

                ul {
                    margin: 0;
                    padding: 0;
    
                    li {
                        list-style-type: none;
                        display: inline-block;

                        a {
                            font-size: 24px;
                            color: $white-color;

                            &:before {
                                position: absolute;
                                content: '';
                                width: 9px;
                                height: 9px;
                                border-radius: 50%;
                                left: 0;
                                right: 0;
                                bottom: -2px;
                                margin-left: auto;
                                margin-right: auto;
                                background-color: $white-color;
                                z-index: 1;
                            }
                            &:after {
                                background-color: #000;
                                border: 1px solid $white-color;
                                width: 18px;
                                height: 18px;
                                bottom: -7px;
                            }
                        }
                        &:nth-child(1) {
                            a {
                                left: 17% !important;
                            }
                        }
                        &:nth-child(2) {
                            a {
                                left: 32% !important;
                            }
                        }
                        &:nth-child(3) {
                            a {
                                left: 50% !important;
                            }
                        }
                        &:nth-child(4) {
                            a {
                                left: 65% !important;
                            }
                        }
                        &:nth-child(5) {
                            a {
                                left: 80% !important;
                            }
                        }
                    }
                }
            }
        }
        .cd-timeline-navigation {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                color: $white-color;
                font-size: 30px;

                &:first-child {
                    i {
                        position: absolute;
                        top: 35px;
                        left: 27px;
                        display: inline-block;
                    }
                }
                &:last-child {
                    i {
                        position: absolute;
                        top: 35px;
                        right: 27px;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .events-content {
        margin: 0;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                padding: 0;
            }
        }
    }
}
/*-- End History --*/

/*-- Video --*/
.video-area {
    position: relative;

    .video-two {
        &:hover {
            a {
                opacity: 1;
            }
        }
        a {
            opacity: 0;
            top: 32%;
        }
    }
}
.video-item {
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.8057328984978493) 32%, rgba(0,0,0,0) 75%);
        opacity: .40;
    }
    a {
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        width: 100px;
        height: 100px;
        line-height: 90px;
        top: 41%;
        left: 0;
        right: 0;
        font-size: 65px;
        margin-left: auto;
        margin-right: auto;
        color: $white-color;
        border: 5px solid $white-color;

        i {
            width: 100px;
            height: 100px;
            line-height: 90px;
            display: inline-block;
        }
        &:hover {
            color: #01091e;
            background-color: $white-color;
        }
    }
}
/*-- End Video --*/

/*-- Team --*/
.team-item {
    margin-bottom: 30px;
    text-align: center;
    transition: $transition;

    &:hover {
        transform: translate(0, -10px);

        .team-top {
            ul {
                li {
                    &:nth-child(1) {
                        opacity: 1;
                        top: -15px;
                    }
                    &:nth-child(2) {
                        opacity: 1;
                        top: -15px;
                    }
                    &:nth-child(3) {
                        opacity: 1;
                        top: -15px;
                    }
                    &:nth-child(4) {
                        opacity: 1;
                        top: -15px;
                    }
                }
            }
        }
    }
    .team-top {
        position: relative;
        margin-bottom: 15px;
            
        ul {
            margin: 0;
            padding: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 2px;
                margin-left: 2px;

                &:nth-child(1) {
                    position: relative;
                    opacity: 0;
                    top: 0;
                    transition: .3s;
                }
                &:nth-child(2) {
                    position: relative;
                    opacity: 0;
                    top: 0;
                    transition: .4s;
                }
                &:nth-child(3) {
                    position: relative;
                    opacity: 0;
                    top: 0;
                    transition: .5s;
                }
                &:nth-child(4) {
                    position: relative;
                    opacity: 0;
                    top: 0;
                    transition: .6s;
                }
                a {
                    display: inline-block;
                    color: $white-color;
                    background-color: #000000;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 15px;

                    i {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                    }
                    &:hover {
                        background-color: $gold-color;
                    }
                }
            }
        }
    }
    .team-bottom {
        h3 {
            color: #d8d6d6;
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 5px;
        }
        span {
            display: block;
            font-weight: 500;
            font-size: 16px;
            color: #a4a2a2;
        }
    }
}
/*-- End Team --*/

/*-- Testimonial --*/
.testimonial-area {
    background-image: url('assets/img/home-one/testimonial.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .90;
    }
    .section-title {
        position: relative;
    }
    .testimonial-item {
        position: relative;
        text-align: center;

        i {
            display: block;
            color: #a7a7a0;
            font-size: 50px;
            margin-bottom: 15px;
        }
        p {
            font-size: $all-size;
            color: $white-color;
            margin-bottom: 25px;
            max-width: 770px;
            margin-left: auto;
            margin-right: auto;
        }
        h3 {
            font-size: 18px;
            color: $white-color;
            position: relative;
            margin-bottom: 0;
            padding-bottom: 8px;

            &:before {
                position: absolute;
                content: '';
                width: 45px;
                height: 1px;
                left: 0;
                right: 0;
                bottom: 0;
                margin-left: auto;
                margin-right: auto;
                background-color: #6c6b6b;
            }
        }
    }
}
/*-- End Testimonial --*/

/*-- Blog --*/
.blog-item {
    background-color: #080808;
    transition: $transition;
    margin-bottom: 30px;

    &:hover {
        transform: translate(0, -10px);
    }
    a {
        display: block;
    }
    .blog-bottom {
        padding: 25px 25px 30px 25px;

        span {
            display: block;
            color: #c4c2c2;
            font-size: 14px;
            margin-bottom: 18px;
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 10px;

            a {
                color: $white-color;

                &:hover {
                    color: $gold-color;
                }
            }
        }
        .blog-btn {
            font-weight: 700;
            color: #c7c5c5;

            &:hover {
                color: $gold-color;
            }
        }
    }
}
/*-- End Blog --*/

/*-- Footer --*/
footer {
    background-color: #020407;

    .footer-item {
        margin-bottom: 30px;

        h3 {
            font-weight: 500;
            font-size: 20px;
            color: $white-color;
            margin-bottom: 25px;
        }
        .footer-logo {
            .footer-logo-img {
                display: block;
                margin-bottom: 25px;
            }
            p {
                font-size: 14px;
                color: #989797;
                margin-bottom: 28px;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        border-radius: 50%;
                        text-align: center;
                        color: #000000;
                        font-size: 18px;
                        background-color: $white-color;

                        &:hover {
                            color: $white-color;
                            background-color: $gold-color;
                        }
                        i {
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        .footer-instagram {
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    list-style-type: none;
                    display: inline-block;
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                    padding-right: 10px;
                    margin-bottom: 10px;

                    a {
                        display: block;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .footer-link {
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: block;
                        color: #cdcccc;
                        font-size: 15px;

                        &:hover {
                            color: $gold-color;
                        }
                    }
                }
            }
        }
        .footer-touch {
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 14px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: $white-color;
                        font-size: 20px;
                    }
                    h4 {
                        font-weight: 500;
                        color: $white-color;
                        font-size: $all-size;
                        margin-bottom: 2px;
                    }
                    a {
                        display: block;
                        color: #cdcccc;
                        font-size: 15px;

                        &:hover {
                            color: $gold-color;
                        }
                    }
                }
            }
        }
    }
}
/*-- End Footer --*/

/*-- Copyright --*/
.copyright-area {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #06080b;

    .copyright-item {
        p {
            margin-bottom: 0;
            font-size: 15px;
            color: $white-color;
            font-weight: 500;

            a {
                display: inline-block;
                font-weight: 700;
                color: $gold-color;

                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}
/*-- End Copyright --*/
/*----- End Home One CSS -----*/

/*----- Home Two CSS -----*/
/*-- Navbar --*/
.side-nav-two {
    a {
        display: inline-block;
        font-weight: 500;
        font-size: 16px;
        color: $white-color;
        background-color: $gold-color;
        padding: 12px 22px;

        &:hover {
            background-color: #050505;
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.banner-area-two {
    background-image: url('assets/img/home-two/banner-main.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 1000px;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .60;
    }
    .banner-content {
        position: relative;
        z-index: 1;
        max-width: 795px;
        margin-top: 30px;

        h1 {
            font-weight: 700;
            font-size: 80px;
            margin-bottom: 0;
        }
        p {
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 2px;
        }
    }
}
/*-- End Banner --*/

/*-- Service --*/
.section-title-two {
    .sub-title {
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 2px;
        margin-bottom: 15px;
    }
}
.service-item {
    margin-bottom: 30px;
    position: relative;

    i {
        display: inline-block;
        color: $white-color;
        font-size: 40px;
        position: absolute;
        top: -10px;
        left: 0;
    }
    .service-inner {
        padding-left: 60px;

        h3 {
            font-weight: 600;
            font-size: 20px;
            color: $white-color;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
            color: #a9a9a9;
        }
    }
}
/*-- End Service --*/

/*-- Team --*/
.team-area-two {
    padding-top: 100px;
}
/*-- End Team --*/

/*-- Product --*/
.product-item {
    text-align: center;
    margin-bottom: 30px;

    img {
        margin-bottom: 22px;
    }
    h3 {
        font-size: 24px;
        font-weight: 600;
        color: $white-color;
        margin-bottom: 15px;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: block;
                color: $white-color;
                font-size: 18px;

                &:hover {
                    color: $gold-color;
                }
            }
        }
    }
    p {
        padding-left: 25px;
        padding-right: 25px;
    }
}
/*-- End Product --*/
/*----- End Home Two CSS -----*/

/*----- Home Three CSS -----*/
/*-- Banner --*/
.banner-area-three {
    background-image: url('assets/img/home-one/banner-main2.jpg');

    p {
        margin-bottom: 20px;
    }
    h1 {
        margin-bottom: 15px;
    }
}
/*-- End Banner --*/

/*-- Service --*/
.service-area-two {
    background-color: $white-color;

    .section-title {
        h2 {
            color: #000000;
        }
    }
    .service-item {
        i {
            color: #000000;
        }
        .service-inner {
            h3 {
                color: #212121;
            }
            p {
                color: #212121;
            }
        }
    }
}
/*-- End Service --*/

/*-- Product --*/
.product-area-two {
    background-color: $white-color;

    .section-title {
        h2 {
            color: #000000;
        }
    }
    .product-item {
        h3 {
            color: #212121;
            margin-bottom: 10px;
        }
        p {
            color: #4e4e4e;
        }
    }
}
/*-- End Product --*/

/*-- Work --*/
.work-area-two {
    background-color: $white-color;

    .section-title {
        h2 {
            color: #000000;
        }
    }
}
/*-- End Work --*/

/*-- Team --*/
.team-area-three {
    background-color: $white-color;

    .section-title {
        h2 {
            color: #000000;
        }
    }
    .team-item {
        .team-bottom {
            h3 {
                color: #212121;
            }
            span {
                color: #a4a2a2;
            }
        }
    }
}
/*-- End Team --*/

/*-- Blog --*/
.blog-area-two {
    background-color: $white-color;

    .section-title {
        h2 {
            color: #000000;
        }
    }
    .blog-item {
        background-color: $white-color;
        text-align: center;
        box-shadow: 0px 0px 20px 0px #dddddd4d;

        .blog-bottom {
            padding: 25px 20px 30px;

            span {
                color: #a3a3a3;
            }
            h3 {
                font-size: 25px;

                a {
                    color: #212121;

                    &:hover {
                        color: $gold-color;
                    }
                }
            }
            p {
                color: #4e4e4e;
            }
            .blog-btn {
                color: #212121;

                &:hover {
                    color: $gold-color;
                }
            }
        }
    }
}
/*-- End Blog --*/
/*----- End Home Three CSS -----*/

/*----- About CSS -----*/
/*-- Page Title --*/
.page-title-area {
    background-color: #020407;

    .page-title-item {
        text-align: center;
        padding-top: 230px;
        padding-bottom: 145px;    

        h2 {
            font-size: 30px;
            margin-bottom: 8px;
            color: $white-color;
            font-weight: 600;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                color: #d4d4d4;
                font-weight: 500;

                span {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background-color: $gold-color;
                    transform: rotate(45deg);
                    position: relative;
                    top: -2px;
                    margin-left: 8px;
                    margin-right: 8px;
                }
                a {
                    display: inline-block;
                    color: #d4d4d4;

                    &:hover {
                        color: $gold-color;
                    }
                }
            }
        }
    }
}
/*-- End Page Title --*/
/*----- End About CSS -----*/

/*----- About Me CSS -----*/
/*-- Page Title --*/
.page-title-area-two {
    background-image: url('assets/img/about-me-main.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(0,0,0,0.8057328984978493) 33%, rgba(0,0,0,0) 60%);
    }
    .page-title-item {
        position: relative;
    }
}
/*-- End Page Title --*/

/*-- About Me --*/
.about-me-area {
    background-color: $white-color;

    .about-me-img {
        margin-bottom: 30px;
    }
    .about-me-content {
        h2 {
            font-weight: 700;
            font-size: 45px;
            margin-bottom: 10px;
            color: #000000;
        }
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 20px;

            li {
                list-style-type: none;
                display: block;
                color: #5b5b5b;
                margin-bottom: 8px;
                font-size: 17px;
                font-weight: 500;
                position: relative;
                padding-left: 15px;

                &:hover {
                    &:before {
                        transform: rotate(0deg);
                    }
                }
                &:before {
                    position: absolute;
                    content: '';
                    width: 6px;
                    height: 6px;
                    left: 0;
                    top: 11px;
                    transform: rotate(45deg);
                    background-color: $gold-color;
                    transition: $transition;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        p {
            margin-bottom: 20px;
            color: #5b5b5b;
        }
        .about-me-inner {
            margin-bottom: 30px;

            i {
                display: inline-block;
                color: #000000;
                font-size: 40px;
                margin-bottom: 12px;
            }
            h3 {
                font-size: 20px;
                font-weight: 700;
                color: #000000;
                margin-bottom: 4px;
            }
            p {
                margin-bottom: 0;
                color: #5b5b5b;
            }
        }
    }
}
/*-- End About Me --*/

/*-- Work --*/
.work-area-three {
    padding-right: 15px;
    padding-left: 15px;

    .work-item {
        margin-bottom: 30px;
    }
}
/*-- End Work --*/
/*----- End About Me CSS -----*/

/*----- Work Details CSS -----*/
.work-details-item {
    margin-bottom: 30px;

    .work-details-img {
        position: relative;
        margin-bottom: 25px;

        img {
            width: 100%;
        }
        a {
            position: absolute;
            left: 0;
            right: 0;
            top: 45%;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            background-color: $gold-color;
            color: $white-color;
            text-align: center;
            font-size: 45px;
            width: 80px;
            height: 80px;

            i {
                width: 80px;
                height: 80px;
                line-height: 80px;
            }
            &:hover {
                background-color: #000000;
            }
        }
    }
    .work-details-content {
        margin-bottom: 50px;

        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 25px;

            li {
                list-style-type: none;
                display: inline-block;
                font-size: 15px;

                span {
                    display: inline-block;
                    margin-left: 3px;
                    margin-right: 3px;
                }
                a {
                    display: inline-block;
                    color: $grey-color;
                    margin-left: 3px;

                    &:hover {
                        color: $gold-color;
                    }
                }
            }
        }
        h3 {
            margin-bottom: 8px;
            color: $white-color;
            font-size: 35px;
            font-weight: 700;
        }
        p {
            margin-bottom: 15px;
        }
    }
    .work-details-quote {
        background-color: $white-color;
        border-radius: 3px;
        padding: 30px 25px 30px 25px;
        position: relative;
        margin-bottom: 60px;

        i {
            display: inline-block;
            font-size: 40px;
            color: #000000;
            margin-bottom: 10px;
        }
        p {
            color: #000000;
            margin-bottom: 10px;
            font-size: 17px;
            font-weight: 600;
            font-style: italic;
        }
        .link {
            text-align: right;

            a {
                display: inline-block;
                color: #000000;

                &:hover {
                    color: $gold-color;
                }
            }
        }
    }
    .work-details-search {
        margin-bottom: 50px;

        form {
            position: relative;

            .form-control {
                height: 50px;
                border-radius: 3px;
                padding-left: 25px;
                font-size: 15px;
                color: #000000;
                border: 0;

                &:focus {
                    box-shadow: none;
                    border: 0;
                }
            }
            .search-btn {
                position: absolute;
                top: 2px;
                right: 0;
                color: #000000;
                font-size: 24px;
                transition: $transition;

                &:hover {
                    color: $gold-color;
                }
            }
        }
    }
    .work-details-work {
        margin-bottom: 50px;

        h3 {
            font-weight: 700;
            font-size: 25px;
            margin-bottom: 45px;
            color: $white-color;
            border-bottom: 1px solid #dddddd59;
            padding-bottom: 12px;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 50px;
                height: 3px;
                background-color: $white-color;
                left: 0;
                bottom: -2px;
            }
        }
        .work-inner {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
    
                li {
                    list-style-type: none;
                    display: inline-block;
                    flex: 0 0 30%;
                    max-width: 30%;
    
                    &:last-child {
                        flex: 0 0 60%;
                        max-width: 60%;
                        margin-left: 10px;
                    }
                }
            }
            h4 {
                text-transform: capitalize;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
    
                a {
                    display: inline-block;
                    color: $white-color;
    
                    &:hover {
                        color: $gold-color;
                    }
                }
            }
            span {
                display: inline-block;
            }
        }
    }
    .work-details-cate {
        h3 {
            font-weight: 700;
            font-size: 25px;
            margin-bottom: 20px;
            color: $white-color;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 12px;
                position: relative;
                padding-left: 20px;

                &:hover {
                    &:before {
                        transform: rotate(0deg);
                    }
                }
                &:before {
                    position: absolute;
                    content: '';
                    width: 6px;
                    height: 6px;
                    left: 0;
                    top: 9px;
                    transform: rotate(45deg);
                    background-color: $gold-color;
                    transition: $transition;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    color: #d5d7d7;

                    &:hover {
                        color: $gold-color;
                    }
                }
            }
        }
    }
}
/*----- End Work Details CSS -----*/

/*----- Video Details CSS -----*/
.video-details-area {
    background-color: $white-color;

    .video-details-item {
        margin-bottom: 30px;

        &:last-child {
            border-right: 0;
        }
        .video-details-video {
            position: relative;
            margin-bottom: 20px;

            img {
                width: 100%;
            }
            a {
                position: absolute;
                left: 0;
                right: 0;
                top: 40%;
                margin-left: auto;
                margin-right: auto;
                border-radius: 50%;
                background-color: $gold-color;
                color: $white-color;
                text-align: center;
                font-size: 45px;
                width: 80px;
                height: 80px;

                i {
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                }
                &:hover {
                    background-color: #000000;
                }
            }
        }
        .video-content {
            margin-bottom: 30px;

            .video-link {
                display: inline-block;
                color: #888888;
                font-size: 15px;
                margin-bottom: 15px;
                padding-left: 15px;
                position: relative;

                &:before {
                    position: absolute;
                    content: '';
                    width: 6px;
                    height: 6px;
                    left: 0;
                    top: 9px;
                    background-color: $gold-color;
                    transform: rotate(45deg);
                }
                &:hover {
                    color: $gold-color;
                }
            }
            h2 {
                font-weight: 700;
                font-size: 45px;
                color: #000000;
                margin-bottom: 10px;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    color: #000000;
                    font-family: $heading-font;
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        display: inline-block;
                        color: $gold-color;
                        font-size: 20px;
                        margin-right: 3px;
                        position: relative;
                        top: 4px;
                    }
                    a {
                        display: inline-block;
                        color: #000000;

                        &:hover {
                            color: $gold-color;
                        }
                    }
                }
            }
        }
        .video-flim {
            h3 {
                font-weight: 700;
                font-size: 35px;
                margin-bottom: 10px;
                color: #000000;
            }
            p {
                margin-bottom: 12px;
                color: #888888;
            }
        }
        .video-social {
            margin-top: 25px;

            span {
                display: inline-block;
                color: $gold-color;
                margin-right: 10px;
            }
            ul {
                margin: 0;
                padding: 0;
                display: inline-block;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        color: $white-color;
                        background-color: #000000;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 18px;

                        &:hover {
                            background-color: $gold-color;
                        }
                        i {
                            width: 35px;
                            height: 35px;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
        .video-introduce {
            padding: 45px 35px 40px;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 25px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    h4 {
                        color: #000000;
                        margin-bottom: 2px;
                        font-weight: 700;
                        font-size: 20px;
                    }
                    span {
                        display: block;
                        color: #888888;
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
            }
            &.video-details-bg {
                background-color: #f9f9f9;
            }
        }
        .work-details-quote {
            background-color: $white-color;
            border-radius: 3px;
            padding: 30px 25px 30px 25px;
            position: relative;
            box-shadow: 0px 0px 20px 0px #dddddd80;
            border-radius: 5px;
            margin-top: 40px;
            margin-bottom: 50px;
    
            i {
                display: inline-block;
                font-size: 40px;
                color: #000000;
                margin-bottom: 10px;
            }
            p {
                color: #000000;
                margin-bottom: 10px;
                font-size: 17px;
                font-weight: 600;
                font-style: italic;
            }
            .link {
                text-align: right;
    
                a {
                    display: inline-block;
                    color: #000000;
    
                    &:hover {
                        color: $gold-color;
                    }
                }
            }
        }
    }
}
.week-area-two {
    background-color: $white-color;

    .section-title {
        h2 {
            color: #000000;
        }
    }
}
/*----- End Video Details CSS -----*/

/*----- Blog Details CSS -----*/
.blog-details-form {
    margin-top: 50px;

    h2 {
        font-weight: 700;
        color: $white-color;
        margin-bottom: 20px;
        font-size: 25px;
    }
    .form-group {
        margin-bottom: 30px;

        label {
            display: block;
            margin-bottom: 10px;
            font-size: 15px;
        }
        .form-control {
            height: 50px;
            border-radius: 2px;
            padding-left: 20px;
            font-size: 15px;
            border: 1px solid #1d1c1c;
            color: $white-color;
            background-color: #1d1c1c;

            &:focus {
                box-shadow: none;
                border: 1px solid #f6f6f6;
            }
        }
        textarea {
            height: auto !important;
        }
    }
    .blog-details-btn {
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 2px;
        padding: 12px 22px;
        transition: $transition;
        background-color: $white-color;

        &:hover {
            color: $white-color;
            background-color: $gold-color;
        }
    }
}
/*----- End Blog Details CSS -----*/

/*----- Partner CSS -----*/
.partner-area {
    background-color: $white-color;

    .partner-item {
        margin-bottom: 30px;
        text-align: center;

        &:hover {
            img {
                opacity: 1;
            }
        }
        img {
            opacity: .80;
            transition: $transition;
        }
    }
}
/*----- End Partner CSS -----*/

/*----- Pricing CSS -----*/
.pricing-area {
    background-color: $white-color;
}
.pricing-item {
    background-color: $white-color;
    margin-bottom: 30px;
    box-shadow: 0px 0px 25px 0px #dddddd82;
    border-radius: 50px 0px 50px 0px;

    .pricing-top {
        background-color: $gold-color;
        position: relative;
        text-align: center;
        border-radius: 50px 0;
        padding-top: 25px;
        padding-bottom: 35px;

        h3 {
            color: $white-color;
            font-weight: 700;
            font-size: 60px;
            position: relative;
            margin-bottom: 0;
            display: inline-block;
        }
        span {
            display: inline-block;
            font-size: 30px;
            color: $white-color;
        }
        p {
            color: $white-color;
            position: relative;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: -8px;
        }
    }
    .pricing-bottom {
        text-align: center;
        padding: 0 60px;
        padding-bottom: 40px;

        ul {
            margin: 0;
            padding: 0;
            margin-top: 15px;

            li {
                list-style-type: none;
                display: block;
                color: #000000;
                display: block;
                padding: 15px 0;
                border-bottom: 1px solid #66666633;
                font-weight: 600;
            }
        }
    }
    .price-btn a{
        color: $gold-color;
        padding: 10px 30px;
        border: 1px solid $gold-color;
        border-radius: 30px;
        display: inline-block;
        margin-top: 30px;
        font-weight: 700;

        &:hover{
            color: $white-color;
            background-color: $gold-color;
        }
    }
}
/*----- End Pricing CSS -----*/

/*----- FAQ CSS -----*/
.faq-area {
    .faq-head {
        h2 {
            margin-bottom: 35px;
            font-weight: 600;
            font-size: 30px;
            margin-top: -7px;
            color: $white-color;
        }
    }
    .faq-wrap {
        margin-bottom: 50px;
    
        &:last-child {
            margin-bottom: 30px;
        }
    }
    .accordion { 
        padding-left: 0;
        margin: 0;
        padding: 0;
    
        p {
            font-size: $all-size;
            display: none;
            padding: 20px 45px 15px 20px;
            margin-bottom: 0;
        }
        a {
            color: $white-color;
            font-size: 17px;
            width: 100%;
            display: block;
            cursor: pointer;
            font-weight: 600;
            padding: 15px 0 15px 18px;
            border: 1px solid #232323;
            border-radius: 8px 8px 0 0;
    
            &:hover {
                color: $gold-color;
            }
            &:after {
                position: absolute;
                right: 20px;
                content: "+";
                top: 10px;
                color: $white-color;
                font-size: 25px;
                font-weight: 700;
            }
        }
        li {
            position: relative;
            list-style-type: none;
            margin-bottom: 30px;
    
            &:first-child {
                border-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .accordion {
        li {
            a.active {
                color: $white-color;
                background-color: #000000;
                border: 1px solid #000000;
            }
            a.active:after {
                content: "-";
                font-size: 25px;
                color: $white-color;
            }
        }
    }
}
/*----- End FAQ CSS -----*/

/*----- Error CSS -----*/
.error-item {
    height: 800px;
    text-align: center;
    margin-top: 30px;

    h1 {
        font-size: 130px;
        font-weight: 700;
        margin-bottom: 8px;
        color: $white-color;
    }
    p {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
        color: $white-color;

        i {
            display: inline-block;
        }
    }
    span {
        display: block;
        color: #646464;
    }
    a {
        display: inline-block;
        color: $white-color;
        background-color: $gold-color;
        padding: 20px 45px;
        margin-top: 70px;
        font-size: 18px;

        &:hover {
            background-color: #000000;
        }
    }
}
/*----- End Error CSS -----*/

/*----- Coming Soon CSS -----*/
.coming-item {
    height: 100vh;
    text-align: center;

    h1 {
        font-size: 75px;
        font-weight: 700;
        font-style: italic;
        margin-bottom: 20px;
        color: $white-color;
    }
    p {
        margin-bottom: 40px;
        max-width: 625px;
        margin-left: auto;
        margin-right: auto;
    }
    .coming-wrap {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        .coming-inner{
            text-align: center;
            background-color: $white-color;
            padding-top: 15px;
            padding-bottom: 12px;
            margin-bottom: 30px;
        
            h3{
                font-size: 45px;
                font-weight: 600;
                color: #000000;
                margin-bottom: 5px;
            }
            p{
                font-size: 16px;
                margin-bottom: 0;
                color: #383838;
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 2px;
            margin-left: 2px;

            a {
                display: block;
                color: $white-color;
                background-color: $gold-color;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                font-size: 16px;

                i {
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                }
                &:hover {
                    background-color: #000000;
                }
            }
        }
    }
}
/*----- End Coming Soon CSS -----*/

/*----- Privacy Policy CSS -----*/
.privacy-area {
    padding-bottom: 50px;
}
.privacy-item {
    margin-bottom: 50px;

    h2 {
        font-size: 26px;
        margin-bottom: 15px;
        font-weight: 600;
        color: $white-color;
    }
    p {
        margin-bottom: 0;
        color: #c0c0c0;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            margin-bottom: 18px;
            color: #c0c0c0;

            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                bottom: -2px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/*----- End Privacy Policy CSS -----*/

/*----- Contact CSS -----*/
.contact-area {
    background-color: $white-color;

    .contact-item {
        margin-bottom: 30px;

        h2 {
            font-weight: 700;
            font-size: 45px;
            color: #000000;
            margin-bottom: 20px;
        }
        .form-group {
            margin-bottom: 30px;
            
            .form-control {
                height: 50px;
                padding-left: 20px;
                font-size: 15px;
                border-radius: 0;
                border: 1px solid #ddd;
                background-color: #f1f1f191;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #000000;
                }
            }
            ::placeholder {
                color: #000;
            }
            textarea {
                height: auto !important;
                padding-top: 15px;
            }
        }
        .contact-btn {
            background-color: #000000;
            color: $white-color;
            transition: $transition;
            opacity: 1;
            border-radius: 0;
            padding: 12px 25px;

            &:hover {
                background-color: $gold-color;
            }
        }
        .list-unstyled {
            margin-top: 10px;
            font-size: 14px;
            color: #dc3545;
        }
        .text-danger {
            margin-top: 10px;
            font-size: 20px;
        }
        .text-success {
            margin-top: 10px;
            font-size: 20px;
        }
        .contact-address {
            .contact-inner {
                position: relative;
                padding-left: 50px;
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }
                .icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $gold-color;
                    font-size: 30px;
                }
                h4 {
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 2px;
                    color: #000000;
                }
                span {
                    display: block;
                    color: #5f5f5f;
                }
                a {
                    display: block;
                    color: #5f5f5f;

                    &:hover {
                        color: $gold-color;
                    }
                }
                ul {
                    margin: 0;
                    padding: 0;
                    margin-top: 15px;

                    li {
                        list-style-type: none;
                        display: inline-block;
                        margin-right: 5px;

                        &:last-child {
                            margin-right: 0;
                        }
                        a {
                            display: block;
                            text-align: center;
                            color: $white-color;
                            background-color: $gold-color;
                            border-radius: 50%;
                            font-size: 20px;

                            i {
                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                            }
                            &:hover {
                                color: $white-color;
                                background-color: #000000;
                            }
                        }
                    }
                }
            }
        }
    }
}
.map-area {
    #map {
        display: block;
        width: 100%;
        border: 0;
        height: 450px;
    }
}
/*----- End Contact CSS -----*/

/*----- Preloader CSS -----*/
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: #000;
}
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}
.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $white-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0; 
    animation: sk-bounce 2.0s infinite ease-in-out;
}
.double-bounce2 {
    animation-delay: -1.0s;
}
@keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
    } 
    50% { 
      transform: scale(1.0);
    }
}
/*----- End Preloader CSS -----*/

/*----- Back to Top CSS ------*/
#toTop {
	position: fixed;
	bottom: 30px;
    right: 0;
	cursor: pointer;
    display: none;
    z-index: 10;
}
.back-to-top-btn {
    i {
        background: $gold-color;
        color: $white-color;
        height: 50px;
        width: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 25px;
        border-radius: 50%;
        transition: $transition;
        margin-right: 28px;
        box-shadow: 0px 0px 14px 0px $gold-color;

        &:hover {
            background: #000;
            color: $white-color;
            box-shadow: 0px 0px 14px 0px #000;
        }
    }
}
/*----- End Back to Top CSS -----*/
